export default class ConsoleLogger {
  constructor(active = true) {
    this.active = active;
  }

  sendEvent(event, value, placeId, mapId, pointId, itemId) {
    if (!this.active) return;

    let data = { event: event, value: value, place_id: placeId, map_id: mapId, point_id: pointId, item_id: itemId }
    if (typeof value === 'object') {
      data.value = null
      data = Object.assign(data, value)
    }
    const array = []
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        array.push(key + ': ' + data[key])
      }
    };
    const result = array.join(', ')
    // eslint-disable-next-line no-console
    console.log(result)
  }

  sendWatchVideoEvent(
    placeId,
    mapId, 
    pointId, 
    itemId,
    videoDuration,
    videoStart,
    videoEnd,
    signed_url_slug,
    isDownloaded,
    accountId
  ) {
    const data = { 
      event: 'watchVideo', 
      place_id: placeId, 
      map_id: mapId,
      point_id: pointId,
      item_id: itemId,
      video_duration: videoDuration,
      video_start: videoStart,
      video_end: videoEnd,
      signed_url_slug: signed_url_slug,
      is_downloaded: isDownloaded,
      account_id: accountId
    }

    console.log(data)
  }
}
