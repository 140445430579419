import TreasureDataLogger from './treasure-data-logger';
import ConsoleLogger from './console-logger';

export default function createLogger() {
  if (window.td) {
    // NOTE: GAとかにも同時に送出したい場合、Facade作って差し替えて下さい
    return new TreasureDataLogger('panorama_user_event');
  } else {
    return new ConsoleLogger();
  }
}
